import React from "react";
import { Col, Row } from "react-bootstrap";
import ExpressIcon from "./ExpressIcon";
import { FaVuejs, FaNpm, FaSass } from "react-icons/fa";
import {
  DiJavascript1,
  DiReact,
  DiNodejs,
  DiMongodb,
  // CgNpm,
  DiGit,
  DiJenkins,
} from "react-icons/di";
import {
  SiTailwindcss,
  SiRedux,
  SiNetlify,
  SiCucumber,
  SiPlaywright,
  SiCypress,
  SiK6,
  SiGithubactions,
} from "react-icons/si";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col title="JavaScript" xs={4} md={2} className="tech-icons">
        <DiJavascript1 title="JavaScript" />
      </Col>
      <Col title="MongoDB" xs={4} md={2} className="tech-icons">
        <DiMongodb title="MongoDB" />
      </Col>
      <Col title="Express.js" xs={4} md={2} className="tech-icons">
        <ExpressIcon title="Express.js" />
      </Col>
      <Col title="React" xs={4} md={2} className="tech-icons">
        <DiReact title="React" />
      </Col>
      <Col title="Node" xs={4} md={2} className="tech-icons">
        <DiNodejs title="Node" />
      </Col>
      <Col title="Vue.js" xs={4} md={2} className="tech-icons">
        <FaVuejs title="Vue.js" />
      </Col>
      <Col title="Sass css preprocessor" xs={4} md={2} className="tech-icons">
        <FaSass title="Sass css preprocessor" />
      </Col>
      <Col title="Tailwind" xs={4} md={2} className="tech-icons">
        <SiTailwindcss title="Tailwind" />
      </Col>
      <Col title="Redux State management" xs={4} md={2} className="tech-icons">
        <SiRedux title="Redux State management" />
      </Col>
      <Col title="Node Package Manager" xs={4} md={2} className="tech-icons">
        <FaNpm title="Node Package Manager" />
      </Col>
      <Col title="Git Version Contron" xs={4} md={2} className="tech-icons">
        <DiGit title="Git Version Contron" />
      </Col>
      <Col title="Jenkins CI CD" xs={4} md={2} className="tech-icons">
        <DiJenkins title="Jenkins CI CD" />
      </Col>
      <Col title="Github Actions" xs={4} md={2} className="tech-icons">
        <SiGithubactions title="Github Actions" />
      </Col>
      <Col title="Netlify" xs={4} md={2} className="tech-icons">
        <SiNetlify title="Netlify" />
      </Col>
      <Col title="Cucubmer" xs={4} md={2} className="tech-icons">
        <SiCucumber title="Cucumber" />
      </Col>
      <Col
        title="playwright Web automation"
        xs={4}
        md={2}
        className="tech-icons"
      >
        <SiPlaywright title="playwright Web automation" />
      </Col>
      <Col title="cypress Web automation" xs={4} md={2} className="tech-icons">
        <SiCypress title="cypress Web automation" />
      </Col>
      <Col
        title="K6 load testing automation"
        xs={4}
        md={2}
        className="tech-icons"
      >
        <SiK6 title="K6 load testing automation" />
      </Col>
    </Row>
  );
}

export default Techstack;

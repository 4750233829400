import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";

// import p1 from "../../Assets/Projects/personal-solo-dev/azadPunchy-FriendPortfolio.gif";
import p2 from "../../Assets/Projects/personal-solo-dev/azadPunchy-E-commerce.gif";
import p3 from "../../Assets/Projects/personal-solo-dev/azadPunchy-NotesApp.gif";
// import p4 from "../../Assets/Projects/personal-solo-dev/azadPunchy-Weather.gif";
import p5 from "../../Assets/Projects/personal-solo-dev/azadPunchy-RandomQuote.gif";
import p6 from "../../Assets/Projects/worked-on/azadpunchy-MrPdf.png";
import p7 from "../../Assets/Projects/worked-on/azadpunchy-WowPdf.png";
import p8 from "../../Assets/Projects/worked-on/azadpunchy-GetPdf.png";
import p9 from "../../Assets/Projects/worked-on/norgic.png";
import p10 from "../../Assets/Projects/worked-on/togee.png";
import p11 from "../../Assets/Projects/worked-on/ticketmanager.png";
import p12 from "../../Assets/Projects/worked-on/sportssystem.gif";
import p13 from "../../Assets/Projects/personal-solo-dev/mastersbacklink.png";
import p14 from "../../Assets/Projects/worked-on/vdotok.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          <strong className="purple">Projects</strong> on which{" "}
          <strong className="purple">I Worked solely</strong>
        </h1>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          {/* <Col md={4} className="project-card">
            <ProjectCard
              imgPath={p1}
              isBlog={false}
              title="Simple Portfolio"
              description="Basic Portfolio site developed with react only"
              link="https://azadpunchy.com/umairvirk"
              src="https://github.com/azadpunchy/1st_PortfolioProject_react"
            />
          </Col> */}

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={p2}
              isBlog={false}
              title="E-commerce"
              description="E-commerce site With MongoDB, Express, Node, React (MERN) Stack project with all kind of filter and load more functionality"
              link="https://digishop.azadpunchy.com/"
              src="https://github.com/azadpunchy/digishop"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={p3}
              isBlog={false}
              title="Notes App"
              description="Complete MERN Stack app basic validation with MongoDb and Node sign-up/login implimented with Json web token"
              link="https://notes.azadpunchy.com/"
              src="https://github.com/azadpunchy/azadpunchy-notes-app"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={p13}
              isBlog={false}
              title="Edit Masters"
              description="Business Website for a local client who offers SEO, Backlink, and other services."
              link="https://mastersbacklink.com"
            />
          </Col>
          {/* <Col md={4} className="project-card">
            <ProjectCard
              imgPath={p4}
              isBlog={false}
              title="Weather App"
              description="Api implementation of Open-Weather with all avalible cities"
              link="https://azadpunchy.github.io/mern-weather/"
              src="https://github.com/azadpunchy/mern-weather"
            />
          </Col> */}

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={p5}
              isBlog={false}
              title="Amazing Quotes"
              description="It is json based random quote genrator json file obtain from type.fit/api/quotes and build with pure js"
              link="https://amazing-quotes-azad-punchy.web.app/"
              src="https://github.com/azadpunchy/Amazing_Quotes"
            />
          </Col>
        </Row>
        <h1 className="project-heading">
          I've recently{" "}
          <strong className="purple">Worked on a few projects</strong> that I'd
          like to share.
        </h1>
        <Row>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={p12}
              isBlog={true}
              title="Sports Systems"
              description="I Worked in this project as Software Development Engineer in Test (SDET)"
              link="https://sportssystems.com/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={p11}
              isBlog={true}
              title="TicketManager"
              description="I Worked in this project as Software Development Engineer in Test (SDET)"
              link="https://ticketmanager.com/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={p14}
              isBlog={true}
              title="Vdotok"
              description="I Worked in this project as Software Development Engineer in Test (SDET)"
              link="https://vdotok.com/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={p10}
              isBlog={true}
              title="togee"
              description="I Worked in this project as Software Development Engineer in Test (SDET)"
              link="https://togee.com"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={p9}
              isBlog={true}
              title="Norgic"
              description="I Worked in this project as Software Development Engineer in Test (SDET)"
              link="https://norgic.com"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={p6}
              isBlog={true}
              title="MRPDF"
              description="I Worked in this project as VUE.js Frontend Developer"
              link="https://mrpdf.net"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={p7}
              isBlog={true}
              title="WOWPDF"
              description="I Worked in this project as VUE.js Frontend Developer"
              link="https://wowpdf.net"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={p8}
              isBlog={true}
              title="GETPDF"
              description="I Worked in this project as VUE.js Frontend Developer"
              link="https://www.getpdf.net"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;

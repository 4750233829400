import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { BiLinkExternal } from "react-icons/bi";

function ProjectCards(props) {
  return (
    <Card className="project-card-view">
      <Card.Img variant="top" src={props.imgPath} alt="card-img" />
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text style={{ textAlign: "justify" }}>
          {props.description}
        </Card.Text>
        <div className="my-style">
          {props.src && (
            <Button
              className={props.isBlog === true ? "hidden" : ""}
              variant="primary"
              href={props.src}
              target="_blank"
            >
              <BiLinkExternal /> &nbsp; src code
            </Button>
          )}
          <Button variant="primary" href={props.link} target="_blank">
            <BiLinkExternal /> &nbsp; live demo
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
}
export default ProjectCards;

import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  SiVisualstudiocode,
  SiPostman,
  SiHeroku,
  SiApachejmeter,
  SiMqtt,
} from "react-icons/si";
import { FaDiscord } from "react-icons/fa";
import { VscGithubAlt } from "react-icons/vsc";
import { SiClickup, SiJira } from "react-icons/si";

function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col title="Jira" xs={4} md={2} className="tech-icons">
        <SiJira title="Jira" />
      </Col>
      <Col title="Clickup" xs={4} md={2} className="tech-icons">
        <SiClickup title="Clickup" />
      </Col>

      <Col title="Jmeter Load testing" xs={4} md={2} className="tech-icons">
        <SiApachejmeter title="Jmeter Load testing" />
      </Col>
      <Col
        title="Mqtt broker for web socket"
        xs={4}
        md={2}
        className="tech-icons"
      >
        <SiMqtt title="Mqtt broker for web socket" />
      </Col>
      <Col title="Github" xs={4} md={2} className="tech-icons">
        <VscGithubAlt title="Github" />
      </Col>
      <Col title="Visual Studio Code" xs={4} md={2} className="tech-icons">
        <SiVisualstudiocode title="Visual Studio Code" />
      </Col>
      <Col title="Postman" xs={4} md={2} className="tech-icons">
        <SiPostman title="Postman" />
      </Col>
      <Col title="Heroku" xs={4} md={2} className="tech-icons">
        <SiHeroku title="Heroku" />
      </Col>
      <Col title="Discord" xs={4} md={2} className="tech-icons">
        <FaDiscord title="Discord" />
      </Col>
    </Row>
  );
}

export default Toolstack;
